<template>
  <div>
    <el-dialog title="上传答题卡"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">

      <el-form :model="form"
               ref="formRef"
               label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="类型：">
              <el-radio-group v-model="form.type">
                <el-radio :label="1">扫描件</el-radio>
                <!-- <el-radio :label="2">手机拍照图片</el-radio> -->
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isAdd">
          <el-col :span="24">
            <el-form-item label="正反面：">
              <el-radio-group v-model="form.image_type">
                <el-radio :label="0">正面</el-radio>
                <el-radio :label="1">反面</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="点击上传：">

              <el-button class=""
                         type="primary"
                         v-show="this.showys"
                         @click="upload">上传
                <!-- :disabled="disabled" -->
              </el-button>
              <el-button type=''
                         v-show="this.showysbar"
                         :disabled="btndisable"
                         @click='uploadFile'>压缩包上传</el-button>

            </el-form-item>
          </el-col>
        </el-row>
        <div v-show="showProgress">
          <el-progress :percentage="percentage"
                       :status="status1"></el-progress>
          <!--  -->
        </div>
        <div class="request">
          <p>请按照如下要求上传答题卡：</p>
          <ol>
            <li v-if="isAdd">请确保上传答题卡的顺序是按照学生答题卡正反面排序。</li>
            <li>请确保扫描结果清晰方正。</li>
          </ol>
        </div>

      </el-form>

      <!-- <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="createSource">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from 'lodash';
export default {
  data () {
    return {
      form: { type: 1, image_type: 0 },
      dialogVisible: false,
      isAdd: 1,
      percentage: 0,
      showProgress: false,
      status1: null,
      disabled: false,
      btndisable: false
    }
  },
  props: {
    showys: {
      type: Boolean,
      default: true
    },
    showysbar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleFileSelect (event) {

      this.selectedFile = event.target.files[0];

    },
    async uploadFile (event) {
      this.uploadProgressDate = 0
      try {
        // 获取 input[type="file"] 元素
        const input = document.getElementById('myInput')
        if (!input) {
          throw new Error('找不到 input[type="file"] 元素')
        }

        // 移除之前的事件监听器
        // input.removeEventListener('change', this.handleFileSelect)


        // 监听 input[type="file"] 的 change 事件
        const fileSelected = new Promise((resolve) => {

          input.addEventListener('click', () => {
            input.value = ''; // 清空input元素的value属性
          });
          input.addEventListener('change', (event) => {

            const file = event.target.files[0] // 获取所选文件
            console.log('fdafadsfa', event.target)
            if (!file) {
              return // 如果没有选择文件，则不执行后续操作
            }



            if (file.name.includes('zip') || file.name.includes('rar')) {
              // 处理所选文件
              this.handleFileSelect(event)
              resolve()

            } else {
              this.$message({
                message: '只能上传 zip 或 rar 文件',
                type: 'error'
              })
              input.value = '' // 清空已选择的文件
              return

            }
          })
        })

        // 触发 input[type="file"] 的 click 事件
        input.click()

        // 等待用户选择文件
        await fileSelected

        // 每个文件切片大小定为45MB
        const sliceSize = 20 * 1024 * 1024

        //文件大小限制为最大1个G，可根据需求修改
        const fileSizeLimit = 1 * 1024 * 1024 * 1024

        const blob = this.selectedFile
        const fileSize = blob.size// 文件大小
        const fileName = blob.name// 文件名
        //计算文件切片总数
        const totalSlice = Math.ceil(fileSize / sliceSize)

        if (fileSize <= fileSizeLimit) {


          let success = false
          let uploadedBytes = 0;
          let uploadedBytesTotal = 0;

          // 循环上传
          for (let i = 0; i < totalSlice; i++) {
            const start = i * sliceSize
            const end = Math.min(fileSize, start + sliceSize)
            const chunk = blob.slice(start, end)


            const formData = new FormData()
            formData.append("chunk", chunk)
            formData.append("name", fileName)
            formData.append("size", fileSize)
            formData.append("chunks", totalSlice)
            formData.append("chunkIndex", i + 1)
            formData.append("edu_paper_id", this.$route.query.paper_id)
            formData.append('sign', this.$getMd5Form(formData))


            // 发送分片到服务器
            await this.$http({

              url: 'api/v1/henan/upload_compress_sheet',
              method: 'post',
              data: formData,
              onUploadProgress: debounce(progressEvent => {

                // 更新已上传的字节数和上传进度
                uploadedBytes += progressEvent.loaded;
                uploadedBytesTotal += progressEvent.total
                this.uploadProgressDate = Math.min(parseFloat((uploadedBytes / uploadedBytesTotal * 100).toFixed(2)), 100);


              }, 100),


              // responseType: 'blob',
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(async (res) => {

              console.log('res', res)

              if (res.data.is_over) {

                // this.$notify({
                //   title: '提示',
                //   message: '上传成功!',
                //   type: 'success'
                // });
                success = true
                this.uploadProgressDate = 100
                this.dialogVisible = false
                this.$parent.successFlag = true
                this.btndisable = true
                this.$parent.uploading = true
                this.$parent.loadingText = "正在上传中..."
                setTimeout(() => {
                  this.$parent.refresh()
                }, 5000)




                // await detBatchTaskid({ test_paper_id: Number(this.$route.query.id || window.localStorage.getItem('paper_test_id')) }).then(resid => {
                //   console.log('resid', resid.data.taskid)
                //   let taskid = resid.data.taskid
                //   let polling = true; // 标志位，判断是否继续轮询

                //   const pollTaskStatus = async () => {
                //     if (polling) {
                //       console.log('taskid', taskid)
                //       if (taskid) {
                //         this.btndisable = true

                //       }
                //       await taskStatus({ taskid: taskid })
                //         .then(statusResult => {
                //           console.log('statusResult', statusResult);
                //           // 处理statusResult的数据

                //           // 判断任务状态是否完成，如果未完成，继续轮询
                //           if (statusResult.data.status == 'SUCCESS') {
                //             this.$message.success("识别成功")
                //             // this.stuList.map(item => {
                //             //   item.identify_status = statusResult.data.status
                //             // })
                //             this.btndisable = false
                //             this.$parent.uploading = false
                //             this.$parent.initData()
                //             this.dialogVisible = false

                //             polling = false
                //           } else {
                //             setTimeout(pollTaskStatus, 3000); // 设置轮询的间隔时间，这里是1秒

                //           }
                //         })
                //         .catch(error => {
                //           console.error('Error polling task status:', error);
                //           // 处理错误情况，例如中断轮询或显示错误信息
                //         });
                //     }
                //   };

                //   pollTaskStatus(); // 开始轮询调用taskStatus获取状态
                // })


              }
            })
            if (success) {
              break // 跳出循环
            }
          }
          if (!success) {
            this.$message.info("压缩包没上传完整")
            this.dialogVisible = false
          }
          // this.refresh()
        } else {

          this.$message({
            message: '文件大小超出1G',
            type: 'error'
          })
        }
      } catch (error) {

      }
    },

    upload () {
      this.percentage = 0
      this.$parent.uploadSubmit(this.form.type, this.form.image_type)
      this.showProgress = true
      this.status1 = null
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      if (this.$parent.sitv) {
        clearInterval(this.$parent.sitv)
      }
      this.dialogVisible = false
      this.percentage = 0
      this.showProgress = false

    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
::v-deep .dialog-footer {
  text-align: center;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

::v-deep .el-dialog {
  margin-top: 10vh !important;
}
.request {
  ol {
    list-style: zero;
  }
  padding: 10px 20px;
  line-height: 24px;
  background: #efefef;
  border-radius: 2px;
}
</style>